import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"

const ShopPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../../images/hero-images/hero-image-3.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Shop</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<h2>Shopping at the ReStore supports safe, decent, affordable housing.</h2>
					<p>
						Our mission is to be a fundraiser for Habitat for Humanity in Oneida and Vilas counties.
						All proceeds support local Habitat for Humanity home construction projects.
					</p>
					<p>
						The ReStore helps the community by recycling gently used items, keeping them from the landfill, and providing a variety of affordable, home-improvement supplies.
					</p>

					<section>
						<h2>Who shops here?</h2>
						<p>Everyone shops at the ReStore!</p>
						<p>Whether you are a do-it-yourselfer, homeowner, renter, landlord, contractor, interior designer, environmentalist, or treasure hunter, make the ReStore your first stop when looking for your next home improvement, renovation, or DIY project.</p>
					</section>

					<section>
						<h2>Why should you shop at ReStore?</h2>
						<p>The prices are great and you never know what you will find.</p>
						<p>But more than that, all proceeds from ReStore sales support Habitat for Humanity’s mission to make sure everyone in the world has a decent place to live. Shopping at the ReStore not only supports your community, but also supports building affordable housing around the world.</p>
					</section>

					<section>
						<h2>What new and gently used goods can you buy at Habitat ReStore?</h2>
						<p>You can find something new every day! Common ReStore finds include:</p>
						<ul>
							<li>Used furniture</li>
							<li>Appliances</li>
							<li>Building materials</li>
							<li>Lighting</li>
						</ul>
						<p>Our ReStore also has:</p>
						<ul>
							<li>Paint and painting supplies</li>
							<li>Small tools and hardware</li>
							<li>Carpets and carpet squares (while supplies last)</li>
						</ul>
					</section>

					<h4>Paint Selection</h4>
					<StaticImage src="../../images/paint-selection.jpg" alt="Paint Selection" />
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default ShopPage

export const Head = () => (
	<Seo
        title="Shop | Habitat For Humanity Northwoods Wisconsin"
    />
)
